import React, { Key, useRef, useState } from 'react';
import { Check } from 'baseui/icon';
import { FILL, Tab, Tabs } from 'baseui/tabs-motion';
import { useCoreAPIURL, useViewPortSize } from '../../../hooks';
import AboutYourSafe, { IAboutYourSafe } from './AboutYourSafe';
import { ErrorForm } from '../../../atoms/Cards';
import PersonalInformation, { IPersonalInformation } from './PersonalInformation';
import Preferences, { IPreferences } from './Preferences';
import SuccessStep from './SuccessStep';
import analyticsCustomEvent from '../../../atoms/js/analyticsCustomEvent';
import { stackStyled, Typography, useStackStyletron } from '../../../theme';

const MainContainer = stackStyled('div', ({ isMobile }: { isMobile: boolean }) => ({
  width: '100%',
  marginTop: isMobile ? '30px' : '10px',
  padding: isMobile ? 0 : '0 20px',
}));

export interface IFullInformation {
  information: IPersonalInformation;
  about: IAboutYourSafe;
  preference: IPreferences;
  [key: string]: any;
}

interface RegisterFormProps {
  showTitleAndSubtitle: boolean;
  showThanks: (value: any) => void;
}

interface Props {}

const RegistrationTabsForm = (props: RegisterFormProps) => {
  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();
  const personalInformationRef = useRef(null) as any;
  const aboutRef = useRef(null) as any;
  const coreAPI_URL = useCoreAPIURL();

  // STATE
  const [active, setActive] = useState<Key>('information');

  // const [currentStep, setCurrentStep] = useState(1);

  const [steps, setSteps] = useState([
    { title: 'Personal Information', key: 'information', step: 1, done: false },
    { title: 'About your safe', key: 'about', step: 2, done: false },
    { title: 'Preferences', key: 'preferences', step: 3, done: false },
  ]);

  const [fullInformation, setFullInformation] = useState({} as IFullInformation);
  // EFFECTS

  // HANLDERS && FUNCTIONS

  function handleChange(value: any, key: string) {
    const fullInformationTmp = { ...fullInformation };
    fullInformationTmp[key] = value;
    setFullInformation(fullInformationTmp);
    if (key === 'preferences') {
      // handleSubmitClicked(fullInformationTmp);
      props.showThanks(fullInformationTmp);
    }
  }

  function handleNextStepClicked(value: any, key: string) {
    handleChange(value, key);
    const stepsTmp = steps.map((s) => {
      if (s.key === key) {
        s.done = true;
      }
      return s;
    });
    setSteps(stepsTmp);

    switch (key) {
      case 'information':
        setActive('about');
        break;
      case 'about':
        setActive('preferences');
        break;
      case 'preferences':
        setActive('last');
        break;
    }
  }

  function handleTabsChanged(value: string) {
    if (value === 'about' && active === 'information') {
      personalInformationRef.current.triggerSubmit();
    }
    if (value === 'preferences' && active === 'about') {
      aboutRef.current.triggerSubmit();
    }
    if ((active === 'about' || active === 'preferences') && value === 'information') {
      setActive(value);
    }
    if (active === 'preferences' && value === 'about') {
      setActive(value);
    }
    if (active === 'information' && value === 'preferences') {
      personalInformationRef.current.triggerSubmit();
    }
  }

  // RENDER

  const { information = {} as IPersonalInformation, about = {} as IAboutYourSafe } =
    fullInformation;

  return (
    <MainContainer isMobile={isMobile} id='register-your-safe-main-container'>
      {!props.showTitleAndSubtitle ? (
        <Typography
          $fontFamily={'Montserrat'}
          $weight={700}
          $tagType={isMobile ? 'h4Mob' : 'h4'}
          $color={theme.stackOnColors.primaryGreenDark}
          $textAlign='left'
          aria-label='Register your product'
        >
          Register your product
        </Typography>
      ) : (
        <>
          <Typography
            $fontFamily={'Montserrat'}
            $weight={700}
            $tagType={isMobile ? 'h4Mob' : 'h4'}
            $color={theme.stackOnColors.primaryGreenDark}
            $textAlign='left'
            $margin='0 0 10px 0'
            aria-label='Welcome to the Stack-On family!'
          >
            Welcome to the Stack-On family!
          </Typography>
          <Typography
            $fontFamily={'Montserrat'}
            $weight={400}
            $tagType={'body'}
            $color={theme.stackOnColors.primaryBlack}
            $textAlign='left'
            $margin='0 0 30px 0'
            aria-label='Before looking for your brand new safes manual, we highly recommend registering it.'
          >
            Before looking for your brand new safe's manual, we highly recommend registering it.
          </Typography>
        </>
      )}
      <Tabs
        activeKey={active}
        onChange={({ activeKey }) => handleTabsChanged(activeKey as string)}
        activateOnFocus
        fill={FILL.fixed}
        overrides={{
          Root: {
            style: () => ({
              minHeight: active !== 'last' ? '60vh' : '15vh',
              // overflowX: 'scroll'
            }),
          },
          TabHighlight: {
            style: () => ({
              backgroundColor: theme.stackOnColors.primaryYellow,
              height: '2px',
              '&:hover': {
                background: 'transparent',
              },
              // borderBottom: `2px solid ${theme.cannonColors.secondaryOrange}`
            }),
          },
          TabBorder: {
            style: () => ({
              backgroundColor: theme.stackOnColors.primaryBlack,
              borderRadius: '2.5px',
              marginTop: '3px',
              height: '2px',
            }),
          },
        }}
      >
        {steps.map((item) => (
          <Tab
            // disabled={index > currentStep - 1}
            disabled={active === 'last'}
            key={item.key}
            title={item.title}
            artwork={() => (
              <div
                style={
                  item.key === active || item.done
                    ? {
                        borderRadius: '50%',
                        width: isMobile ? '20px' : '30px',
                        color: 'white',
                        height: isMobile ? '20px' : '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: isMobile ? '12px' : '15px',
                        background:
                          item.done === true
                            ? theme.stackOnColors.primaryGreen
                            : theme.stackOnColors.primaryYellow,
                      }
                    : {
                        borderRadius: '50%',
                        width: isMobile ? '20px' : '30px',
                        height: isMobile ? '20px' : '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.stackOnColors.primaryBlackDark,
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: theme.stackOnColors.secondarySilver,
                      }
                }
              >
                {item.done ? <Check size={isMobile ? '18px' : '25px'} /> : item.step}
              </div>
            )}
            overrides={{
              Tab: {
                style: () => ({
                  background: 'transparent',
                  fontFamily: 'Montserrat !important',
                  justifyContent: 'flex-start',
                  fontSize: 'min(2.4vw, 18px) !important',
                  fontWeight: 700,
                  color: item.done
                    ? theme.stackOnColors.primaryGreen
                    : active === item.key
                    ? theme.stackOnColors.primaryYellow
                    : theme.stackOnColors.primaryBlack,
                  '&:active': {
                    fontSize: '18px !important',
                  },
                  '&:hover': {
                    background: 'transparent',
                  },
                }),
              },
            }}
          >
            {item.key === 'information' && (
              <PersonalInformation
                ref={personalInformationRef}
                informationProp={information}
                stepPassed={(value: IPersonalInformation) =>
                  handleNextStepClicked(value, 'information')
                }
              />
            )}
            {item.key === 'about' && (
              <AboutYourSafe
                ref={aboutRef}
                stepPassed={(value: IAboutYourSafe) => handleNextStepClicked(value, 'about')}
                aboutYourSafeProp={about}
              />
            )}
            {item.key === 'preferences' && (
              <Preferences
                stepPassed={(value) => {
                  analyticsCustomEvent('register_safe');
                  handleNextStepClicked(value, 'preferences');
                }}
              />
            )}
          </Tab>
        ))}
      </Tabs>
    </MainContainer>
  );
};

export default RegistrationTabsForm;
