import React, { useEffect, useState } from "react";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Questions } from "./helpers/preferencesQuestions";
import { Button, TextAreaInput } from "../../../atoms";
import scrollTo from "gatsby-plugin-smoothscroll";
import { stackStyled, Typography, useStackStyletron } from "../../../theme";
import { useViewPortSize } from "../../../hooks";

export interface IPreferences {
  suggestion: string;
  questions: Array<{
    question: string;
    answer: string;
    options: { label: string }[];
  }>;
}

const MainContainer = stackStyled("div", (props: {isMobile: boolean}) => ({
  width: "auto",
  display: "flex",
  justifyContent: "center",
  paddingLeft: props.isMobile ? 0 : '10%',
  paddingRight: props.isMobile ? 0 : '10%',
  flexDirection: "column",
  flex: 1,
}));

const QuestionContainer = stackStyled(
  "div",
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: "30px 0px 0px 0px",
    maxWidth: isMobile ? "80vw" : "50vw",
  })
);

const SubmitButtonContainer = stackStyled("div", {
  marginTop: "60px",
  width: "291px",
});

const ButtonsContainer = stackStyled("div", {
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

interface Props {
  stepPassed: (value: IPreferences) => void;
}

const Preferences = ({ stepPassed }: Props) => {
  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();

  // STATE
  const [questions, setQuestions] = useState({
    suggestion: "",
    questions: Questions,
  });

  const [numberTest, setNumberTest] = useState(0);
  useEffect(() => {}, [questions.suggestion]);

  // EFFECTS

  useEffect(() => {
    setTimeout(() => {
      scrollTo("#register-your-safe-main-container");
    }, 200);
  }, []);

  // HANDLERS && FUNCTIONS

  const handleChange = (value: string, index: number) => {
    const tmp = { ...questions };
    if (index === 99) {
      tmp.suggestion = value;
    } else {
      tmp.questions[index].answer = value;
    }
    setQuestions(tmp);
  };
  //EFFECTS

  // RENDER
  return (
    <MainContainer isMobile={isMobile}>
      <div>
        <TextAreaInput
          noMarginLeft
          rows={4}
          errorMessage="Field Required"
          placeholder="Leave us your comments to improve your next experience"
          error={undefined}
          positive={questions.suggestion !== ""}
          label="Any suggestions for us?"
          value={questions.suggestion}
          onChange={(e: any) => handleChange(e.currentTarget.value, 99)}
        />
        {questions.questions.map((item, index) => {
          return (
            <QuestionContainer isMobile={isMobile}>
              <Typography
                $fontFamily={"Montserrat"}
                $weight={700}
                $tagType={"body"}
                $color={theme.stackOnColors.primaryBlackDark}
                $textAlign={"left"}
              >
                {item.question}
              </Typography>
              <div>
                {item.options.map((option) => {
                  const { label = "" } = option;
                  const _label1 = label.includes("(")
                    ? label.split("(")[0]
                    : label;
                  const _label2 = label.includes("(")
                    ? "(".concat(label.split("(")[1])
                    : "";
                  const _label3 = label.includes("(")
                    ? label.split(")")[1]
                    : "";
                  const isChecked = item.answer === option.label;
                  return (
                    <Checkbox
                      checked={item.answer === option.label}
                      onChange={() => handleChange(option.label, index)}
                      overrides={{
                        Checkmark: {
                          style: {
                            borderLeftColor:
                              theme.stackOnColors.secondarySilver,
                            borderRightColor:
                              theme.stackOnColors.secondarySilver,
                            borderTopColor: theme.stackOnColors.secondarySilver,
                            borderBottomColor:
                              theme.stackOnColors.secondarySilver,
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            borderWidth: "1.5px",
                            backgroundColor: isChecked ? theme.stackOnColors.primaryGreen : theme.stackOnColors.primaryWhite
                          },
                        },
                      }}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      <Typography
                        $fontFamily={"Montserrat"}
                        $weight={400}
                        $tagType={"body"}
                        $color={theme.stackOnColors.primaryBlack}
                        $textAlign={"left"}
                        $margin='0'
                        $paddingBottom={"10px"}
                      >
                        {_label1}
                        <span
                          style={{
                            color: theme.stackOnColors.secondarySilver,
                          }}
                        >
                          {_label2}
                        </span>
                        {_label3}
                      </Typography>
                    </Checkbox>
                  );
                })}
              </div>
            </QuestionContainer>
          );
        })}
      </div>
      <ButtonsContainer>
        <SubmitButtonContainer>
          <Button.RoundedButton
            onClick={() => stepPassed(questions)}
            size={ isMobile ? "mini" : "large"}
            type="submit"
            color="primary"
            fullWidth
          >
            Register my product
          </Button.RoundedButton>
        </SubmitButtonContainer>
      </ButtonsContainer>
    </MainContainer>
  );
};

export default Preferences;
