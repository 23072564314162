import { ArrowRight } from "baseui/icon";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import { CustomDropdown, CustomInput } from "../../../atoms";
import PhoneIcon from "-!svg-react-loader!../../../assets/phone.svg";
import { states } from "./helpers/states";
import { Button } from "../../../atoms";
import invalidCharsInput from "../../../atoms/js/invalidCharsInput";
import { stackStyled, useStackStyletron } from "../../../theme";
import { useViewPortSize } from "../../../hooks";

const MainContainer = stackStyled("div", (props: {isMobile: boolean}) => ({
  width: "auto",
  display: "flex",
  justifyContent: "center",
  paddingLeft: props.isMobile ? 0 : '10%',
  paddingRight: props.isMobile ? 0 : '10%',
}));

const SubmitButtonContainer = stackStyled("div", {
  display: 'flex',
  justifyItems: 'center',
  marginTop: "60px",
  width: "291px",
});

const FlexContainer = stackStyled("div", {
  display: "flex",
  alignItems: "center",
  flex: 1,
  width: "100%",
});

const PhoneIconStyled = stackStyled(PhoneIcon, (props) => ({
  stroke: props.$theme.stackOnColors.secondarySilver,
}));

export interface IPersonalInformation {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  [key: string]: string;
}

interface Props {
  stepPassed: (value: IPersonalInformation) => void;
  informationProp: IPersonalInformation;
}

const PersonalInformation = forwardRef(
  ({ stepPassed = () => {}, informationProp }: Props, ref) => {
    //HOOKS

    const [isMobile, isTablet] = useViewPortSize();
    const [css, theme] = useStackStyletron();

    const statesFormated = useMemo(() => {
      return states.map((s, index: number) => {
        s.id = index.toString();
        return s;
      });
    }, [states]);

    // STATE

    const [information, setInformation] = useState({} as IPersonalInformation);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);

    //EFFECT
    useEffect(() => {
      setInformation(informationProp);
    }, [informationProp]);

    // HANDLERS && FUNCTIONS

    function handleChange(value: string, key: string) {
      if (key === "email") {
        const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          value
        );
        setEmailInvalid(!valid);
      }
      const tmp = { ...information };
      tmp[key] = value;
      setInformation(tmp);
    }

    // RENDER

    const {
      firstName = "",
      lastName = "",
      email = "",
      phone = "",
      addressLine1 = "",
      addressLine2 = "",
      city = "",
      state = "",
      zipCode = "",
    } = information;

    // ENABLE NEXT BUTTON 
  const enable = (firstName != '') && (lastName != '') && (email != '') && (phone != '') && (addressLine1 != "");

    // EXPOSED METHODS
    useImperativeHandle(ref, () => ({
      triggerSubmit: () => {
        setSubmitClicked(true);
        if (
          firstName !== "" &&
          lastName !== "" &&
          email !== "" &&
          !emailInvalid &&
          phone !== "" &&
          addressLine1 !== ""
        ) {
          stepPassed(information);
        }
      },
    }));

    return (
      <MainContainer isMobile={isMobile}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!emailInvalid && phone.length >= 10) {
              stepPassed(information);
            }
          }}
          style={{ width: !isMobile ? "100%" : "100%" }}
        >
          <FlexContainer>
            <CustomInput
              isRegister={true}
              noMarginLeft
              errorMessage="Field Required"
              error={firstName === "" && submitClicked}
              positive={firstName !== ""}
              label="First Name"
              placeholder="E.g. John"
              value={firstName}
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, "firstName")
              }
              required
              requiredStyle
            />
            <div style={{ width: "26px" }} />
            <CustomInput
              isRegister={true}
              errorMessage="Field Required"
              error={lastName === "" && submitClicked}
              positive={lastName !== ""}
              label="Last name"
              placeholder="E.g. Doe"
              value={lastName}
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, "lastName")
              }
              required
              requiredStyle
            />
          </FlexContainer>
          <CustomInput
            isRegister={true}
            noMarginLeft
            errorMessage={
              email !== "" && emailInvalid ? "Email Invalid" : "Field Required"
            }
            error={(email === "" && submitClicked) || emailInvalid}
            positive={email !== "" && !emailInvalid}
            label="Email"
            placeholder="Enter an email that you constanly check"
            value={email}
            onChange={(e: any) => handleChange(e.currentTarget.value, "email")}
            required
            requiredStyle
          />
          <CustomInput
            isRegister={true}
            noMarginLeft
            startEnhancer={() => <PhoneIconStyled />}
            errorMessage={phone === "" ? "Field required" : "Follow the format"}
            type="number"
            placeholder="+1-000-000-0000"
            error={
              (phone === "" && submitClicked) ||
              (phone.length < 10 && phone !== "")
            }
            label="Phone"
            value={phone}
            positive={phone !== ""}
            onKeyDown={(e: any) => invalidCharsInput(e, ["e", "-"])}
            onChange={(e: any) => handleChange(e.currentTarget.value, "phone")}
            required
            requiredStyle
          />
          <CustomInput
            isRegister={true}
            noMarginLeft
            errorMessage="Field Required"
            error={addressLine1 === "" && submitClicked}
            label="Address Line 1"
            placeholder="E.g. 90 Bedford Street"
            value={addressLine1}
            onChange={(e: any) =>
              handleChange(e.currentTarget.value, "addressLine1")
            }
            positive={addressLine1 !== ""}
            required
            requiredStyle
          />
          <CustomInput
            isRegister={true}
            noMarginLeft
            label="Address Line 2"
            placeholder="Apartment number or letter"
            value={addressLine2}
            positive={addressLine2 !== ""}
            onChange={(e: any) =>
              handleChange(e.currentTarget.value, "addressLine2")
            }
          />
          <FlexContainer>
            <CustomInput
              isRegister={true}
              noMarginLeft
              label="City"
              placeholder="E.g. New York"
              value={city}
              positive={city !== ""}
              onChange={(e: any) => handleChange(e.currentTarget.value, "city")}
            />
            <div style={{ width: "26px" }} />
            <CustomDropdown
              isRegister={true}
              clearable={false}
              placeholder="Choose an option"
              background="white"
              label="State"
              onChange={(value) => handleChange(value[0].label, "state")}
              options={statesFormated}
              value={state !== "" ? [{ label: state }] : []}
              positive={state !== ""}
              aria-label="Choose state"
            />
          </FlexContainer>
          <CustomInput
            isRegister={true}
            noMarginLeft
            type="number"
            min={0o765}
            max={99999}
            error={zipCode.length < 5 && zipCode.length > 0}
            errorMessage="Just five digits allowed"
            placeholder="Enter a 5 digit zip code"
            label="Zip Code"
            value={zipCode}
            onKeyPress={(e: any) => {
              if (e.target.value.length > 4) {
                e.preventDefault();
              }
            }}
            onKeyDown={(e: any) => invalidCharsInput(e, ["e", "-", "+"])}
            onChange={(e: any) =>
              handleChange(e.currentTarget.value, "zipCode")
            }
            positive={zipCode !== ""}
            aria-label="Zip Code"
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SubmitButtonContainer>
              <Button.RoundedButton
                onClick={() => {
                  setSubmitClicked(true);
                }}
                size={ isMobile ? "mini" : "large"}
                type="submit"
                color="primary"
                disabled={!enable}
                fullWidth
              >
                Next step
              </Button.RoundedButton>
            </SubmitButtonContainer>
          </div>
        </form>
      </MainContainer>
    );
  }
);

export default PersonalInformation;
