import React, { useEffect, useState } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider } from '../components/layout';
import { stackStyled } from '../theme';
import { useViewPortSize } from '../hooks';
import { IFile } from '../@types';
import { StackOnThemeT } from '../theme/StacksOnTheme';
import { SearchManualUI } from '../components/sections';
import useCoreAPIURL from '../hooks/useCoreAPIURL';
import { IAboutYourSafe } from '../components/sections/RegisterSafeFormBySteps/AboutYourSafe';
import RegistrationTabsForm from '../components/sections/RegisterSafeFormBySteps/RegistrationTabsForm';

export const query = graphql`
  query {
    strapiProductsManuals {
      productCategory {
        subCategories {
          title
          id
          warrantyDoc {
            alternativeText
            caption
            created_at
            ext
            hash
            mime
            name
            provider
            size
            url
          }
        }
        title
      }
    }
  }
`;

interface IProductSubCategories {
  id: number;
  title: string;
  warrantyDoc: IFile;
}

interface IProductCategories {
  title: string;
  id: number;
  subCategories: Array<IProductSubCategories>;
}

interface IProductManuals {
  strapiProductsManuals: { productCategory: Array<IProductCategories> };
}

interface IProductManualsProps {
  isMobile?: boolean;
  $color?: string;
  $theme?: StackOnThemeT;
}

const Container = stackStyled('div', {
  maxWidth: '1280px',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  padding: '50px 0',
});

const ProductManuals: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IProductManuals>(query);

  const [isMobile, isTablet] = useViewPortSize();
  const coreAPI_URL = useCoreAPIURL();

  const [thanksText, setThanksText] = useState('Thank you for registering your safe!');
  const [showElement, setShowElement] = useState(true);
  const [showFetchError, setShowFetchError] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);

  async function sendInfo(value: any) {
    const response = await fetch(coreAPI_URL + '/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  async function uploadFile(value: any) {
    const { about = {} as IAboutYourSafe } = value;
    const { proofOfPurchase = {} as File, serialNumber } = about;
    const myRenamedFile = new File(
      [proofOfPurchase],
      new Date().getTime().toString() +
        '-' +
        serialNumber.toString() +
        '-upload-register-safe-file' +
        '.' +
        proofOfPurchase.name.split('.')[proofOfPurchase.name.split('.').length - 1]
    );
    const formData = new FormData();
    formData.append('file', myRenamedFile);

    const response = await fetch(coreAPI_URL + '/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        // Accept: 'multipart/form-data',
      },
      body: formData,
    })
      .then((r) => r.json())
      .then((r) => {
        const { data = {} as { url: string }, message } = r;
        if (message === 'File saved.') {
          const { url = '' } = data;
          value.about.proofOfPurchase = url;
          sendInfo(value);
        } else {
          setShowFetchError(true);
        }
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  let alreadyRegister:boolean = false;

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    } else {
      if (localStorage.getItem('alreadyRegister') === 'true') {alreadyRegister = true}
    }
  }, []);

  let showSearchManual = !formCompleted && (isMobile || isTablet) && !alreadyRegister;

  return (
    <LayoutProvider seo={false} locationSearch={props.location.search}>
      <div id='main'>
        {showSearchManual ? (
          <Container>
            <RegistrationTabsForm
              showTitleAndSubtitle={true}
              showThanks={(dataInfo: any) => {
                setFormCompleted(true);
                localStorage.setItem('alreadyRegister', 'true');
                setThanksText('Thank you for registering your safe!');
                uploadFile(dataInfo);
              }}
            />
          </Container>
        ) : (
          <SearchManualUI
            strapiProductsManuals={data.strapiProductsManuals}
            thanksText={thanksText}
          />
        )}
      </div>
    </LayoutProvider>
  );
};

export default ProductManuals;
