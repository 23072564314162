import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ParagraphSmall } from 'baseui/typography';
import { graphql, useStaticQuery } from 'gatsby';
import { CustomDropdown, CustomInput } from '../../../atoms';
import { Button } from '../../../atoms';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { DatePicker } from 'baseui/datepicker';
import { stackStyled, useStackStyletron } from '../../../theme';
import { useViewPortSize } from '../../../hooks';
import '../../../atoms/Input/input.css';
import CalendarIcon from '-!svg-react-loader!../../../assets/calendar.svg'

// GRAPHQL QUERY
const query = graphql`
  query {
    allStrapiRetailers {
      nodes {
        displayName
      }
    }
  }
`;

const MainContainer = stackStyled('div', (props: { isMobile: boolean }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: props.isMobile ? 0 : '10%',
  paddingRight: props.isMobile ? 0 : '10%',
}));

const SubmitButtonContainer = stackStyled("div", {
  display: 'flex',
  justifyItems: 'center',
  marginTop: "60px",
  width: "291px",
});

const DropdownContainer = stackStyled('div', {
  margin: '15px 0px 0px 0px',
  minWidth: '50%',
  alignSelf: 'baseline',
  flex: 1,
});

interface IData {
  allStrapiRetailers: { nodes: Array<{ displayName: string }> };
}

export interface IAboutYourSafe {
  modelNumber: string;
  serialNumber: string;
  dateOfPurchase: string;
  purchasedFrom: string;
  proofOfPurchase: File;
}

interface Props {
  aboutYourSafeProp: IAboutYourSafe;
  stepPassed: (value: IAboutYourSafe) => void;
}

const AboutYourSafe = forwardRef(
  ({ aboutYourSafeProp = {} as IAboutYourSafe, stepPassed }: Props, ref) => {
    //HOOKS
    const data = useStaticQuery<IData>(query);

    const [isMobile, isTablet] = useViewPortSize();

    const [css, theme] = useStackStyletron();

    const retailersFormated = useMemo(() => {
      const { nodes = [] } = data.allStrapiRetailers;
      return nodes.map((node, index) => ({
        label: node.displayName,
        id: index.toString(),
      }));
    }, [data]);

    // STATE

    const [about, setAbout] = useState(aboutYourSafeProp);
    const [invalidSerialNumber, setInvalidSerialNumber] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [activeGreen, setActiveGreen] = useState(false)
    const [activeGreenLight, setActiveGreenLight] = useState(false)


    // EFFECTS

    useEffect(() => {
      setAbout(aboutYourSafeProp);
    }, [aboutYourSafeProp]);

    useEffect(() => {
      setTimeout(() => {
        scrollTo('#register-your-safe-main-container');
      }, 200);
    }, []);

    // HANDLERS && FUNCTIONS

    function handleChange(value: any, key: string) {
      const tmp = { ...about };
      tmp[key] = value;
      setAbout(tmp);
    }

    function handleChangeColorGreenLight() {
      if (activeGreenLight) {
        setActiveGreen(false)
      } else {
        setActiveGreenLight(true)
    }}

    function handleChangeClose() {
      if (activeGreenLight) {
        setActiveGreenLight(false)
        setActiveGreen(true)
      }}

    // RENDER
    const {
      serialNumber = '',
      modelNumber = '',
      purchasedFrom = '',
      dateOfPurchase = '',
      proofOfPurchase = {} as File,
    } = about;

    useImperativeHandle(ref, () => ({
      triggerSubmit: () => {
        setSubmitClicked(true);
        if (serialNumber !== '' && modelNumber !== '') {
          stepPassed(about);
        }
      },
    }));

  const enable = (serialNumber != '') && (modelNumber != '') && (purchasedFrom != "") && (dateOfPurchase != '') && ( proofOfPurchase.name != undefined) ;

    return (
      <MainContainer isMobile={isMobile} id='main'>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            stepPassed(about);
          }}
          style={{ width: '100%' }}
        >
          <CustomInput
            label='Serial Number'
            tooltipText='The serial number is located on the shipping  carton and in the top left corner of the safe’s right-side panel.'
            value={serialNumber}
            onChange={(e) => {
              handleChange(e.currentTarget.value, 'serialNumber');
              // try {
              //   if (/^[0-9-]*$/.test(e.currentTarget.value)) {
              //     setInvalidSerialNumber(false);
              //     handleChange(
              //       e.currentTarget.value
              //         .match(/\d{4}(?=\d{2,3})|\d+/g)
              //         .join('-'),
              //       'serialNumber'
              //     );
              //   } else {
              //     setInvalidSerialNumber(true);
              //   }
              // } catch (e) {
              //   console.log('Error', e);
              // }
            }}
            placeholder='Write your safe serial number'
            required
            requiredStyle
            positive={serialNumber !== '' && !invalidSerialNumber}
            error={(serialNumber === '' && submitClicked) || invalidSerialNumber}
            errorMessage='Field required'
            noMarginLeft
            isRegister={true}
            bottomLabel='Label on the front/back or model of your product'
          />
          <CustomInput
            label='Model Number'
            value={modelNumber}
            onChange={(e) => handleChange(e.currentTarget.value, 'modelNumber')}
            placeholder='E.g. 24'
            noMarginLeft
            positive={modelNumber !== ''}
            error={modelNumber === '' && submitClicked}
            errorMessage='Field Required'
            requiredStyle
            required
            isRegister={true}
          />
          <ParagraphSmall
            margin={0}
            color={theme.stackOnColors.primaryBlackDark}
            $style={{
              paddingTop: '26px',
              fontSize: '14px',
              fontWeight: 400,
              textAlign: 'left',
              fontFamily: 'Montserrat !important',
            }}
          >
            Date of purchase <span style={{color: theme.stackOnColors.primaryYellow, marginLeft: '-2px'}}>*</span>
          </ParagraphSmall>
          <DatePicker
            maxDate={new Date()}
            value={dateOfPurchase !== '' ? new Date(dateOfPurchase) : new Date()}
            onChange={({ date }) =>
              handleChange(
                Array.isArray(date) ? date.toString() : [date].toString(),
                'dateOfPurchase'
              )
            }
            // placeholder='Hello'
            overrides={{
              Root: {
                style: {
                  background: theme.stackOnColors.primaryWhite,
                },
              },
              InputWrapper: {
                style: {
                  background: theme.stackOnColors.primaryWhite,
                  margin: '10px 10px 10px 0px',
                  overflow: 'hidden'
                },
              },
              Input: {
                props: {
                  overrides: {
                    Root: {
                      style: {
                        backgroundColor: theme.stackOnColors.primaryWhite,
                        borderStyle: 'none',
                      },
                    },
                    InputContainer: {
                      style: {
                        backgroundColor: theme.stackOnColors.primaryWhite,
                      }
                    },
                    Input: {
                      style: {
                        border: `2px solid ${theme.stackOnColors.primaryGrey}`,
                        borderRadius: '10px',
                        background: theme.stackOnColors.primaryWhite,
                        cursor: 'pointer',
                        fontSize: '17px',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        color: theme.stackOnColors.primaryBlack,
                        overflow: 'hidden'
                      },
                    },
                  },
                },
              },
            }}
          >
          </DatePicker>
          <DropdownContainer>
            <CustomDropdown
              clearable={false}
              placeholder='Choose an option'
              background='white'
              label='Purchased From (Retailer Name)'
              errorMessage='Field required'
              error={purchasedFrom === '' && submitClicked}
              onChange={(value) => handleChange(value[0].label, 'purchasedFrom')}
              options={retailersFormated}
              value={purchasedFrom !== '' ? [{ id: '', label: purchasedFrom }] : []}
              positive={purchasedFrom !== ''}
              noMarginLeft
              requiredStyle
              required
              isRegister={true}
            />
          </DropdownContainer>
          <CustomInput
            id='fileInput'
            isRegister={true}
            type='file'
            bottomLabel='Proof of purchase is required for all Warranty Claims. We recommend uploading your receipt of max 5MB now to expedite any future warranty claims.'
            label='Proof of purchase'
            noMarginLeft
            required
            requiredStyle
            onChange={(e) =>
              handleChange(
                e.currentTarget.files?.length > 0 ? e.currentTarget.files[0] : proofOfPurchase,
                'proofOfPurchase'
              )
            }
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SubmitButtonContainer>
                <Button.RoundedButton
                  onClick={() => {
                    setSubmitClicked(true);
                  }}
                  size={isMobile ? 'mini' : 'large'}
                  type='submit'
                  color='primary'
                  disabled={!enable}
                  fullWidth
                >
                  Next step
                </Button.RoundedButton>
            </SubmitButtonContainer>
          </div>
        </form>
      </MainContainer>
    );
  }
);

export default AboutYourSafe;
