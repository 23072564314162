export const Questions = [
  {
    question:
      "I want to receive emails about new products and exciting Stack-On news!",
    options: [
      {
        label: "Yes, give me the details",
      },
      {
        label: "No, I’ll be the last to know",
      },
    ],
    answer: "Yes, give me the details",
  },
];
